'use strict';

import AbstractEndpoint from './abstract.endpoint';
import ItemResponse from "../../../entities/response/item.response";
import ErrorResponse from "../../../entities/response/error.response";

const BASE_URL = 'config';

export default class ConfigEndpoint extends AbstractEndpoint
{
    fetch(force = false) {

        return new Promise((resolve, reject) => {

            let url = BASE_URL;

            if(true === force) {
                url += '?' + (new Date().getTime());
            }

            this.client
                .get(url)
                .then(response => {
                    resolve(new ItemResponse(response.status, response.data.data));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }
}