'use strict';

import GameEntity from '../../../entities/game/game.entity';
import AbstractEndpoint from './abstract.endpoint';
import ErrorResponse from "../../../entities/response/error.response";
import ItemResponse from "../../../entities/response/item.response";
import CollectionResponse from "../../../entities/response/collection.response";
import PlatformSuggest from "../../../entities/response/platform-suggest.response";

const BASE_URL = 'game';

export default class GameEndpoint extends AbstractEndpoint
{
    fetch(id) {

        return new Promise((resolve, reject) => {

            this.client
                .get(BASE_URL + '/' + id)
                .then(response => {
                    resolve(new ItemResponse(response.status, GameEntity.fromObject(response.data.data)));
                })
                .catch(error => {
                    console.log(error);
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }

    add(serviceId, serviceIdentifier) {

        return new Promise((resolve, reject) => {

            this.client
                .post(BASE_URL, { service: serviceId, service_game_identifier: serviceIdentifier.toString() })
                .then(response => {
                    resolve(new ItemResponse(response.status, GameEntity.fromObject(response.data.data)));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }

    remove(id) {

        return new Promise((resolve, reject) => {

            this.client
                .delete(BASE_URL + '/' + id)
                .then(() => {
                    resolve(true);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }

    refresh(id) {

        return new Promise((resolve, reject) => {

            this.client
                .put(BASE_URL + '/' + id)
                .then(() => {
                    resolve(true);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }

    list(params) {

        return new Promise((resolve, reject) => {

            this.client
                .get(BASE_URL, { params })
                .then(response => {

                    const data = response.data.data;
                    const collection = new CollectionResponse(response.status, data.pagination, data.aggregations);

                    response.data.data.items && response.data.data.items.map(item => {
                        collection.add(GameEntity.fromObject(item));
                    });

                    resolve(collection);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }

    suggest(title) {

        return new Promise((resolve, reject) => {

            this.client
                .get(BASE_URL + '/suggest', {params: {query: title, limit: 100}})
                .then(response => {

                    const data = response.data.data;
                    const platforms = PlatformSuggest.fromResponse(data);

                    resolve(platforms.items);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }

    recommendations(id) {

        return new Promise((resolve, reject) => {

            this.client
                .get(BASE_URL + '/' + id + '/recommended', {params: {limit: 7}})
                .then(response => {

                    const collection = new CollectionResponse(response.status);

                    response.data.data && response.data.data.map(item => {
                        collection.add(GameEntity.fromObject(item));
                    });

                    resolve(collection);
                })
                .catch(error => {
                    console.log(error)
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }
}