'use strict';

import AbstractEndpoint from './abstract.endpoint';
import ErrorResponse from '../../../entities/response/error.response';
import ItemResponse from '../../../entities/response/item.response';
import MediumEntity from '../../../entities/game/medium.entity';

const BASE_URL = 'medium';

export default class GameMediumEndpoint extends AbstractEndpoint
{
    add(medium) {

        return new Promise((resolve, reject) => {

            this.client
                .post(BASE_URL, medium)
                .then(response => {
                    resolve(new ItemResponse(response.status, MediumEntity.fromObject(response.data.data)));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }

    update(id, medium) {

        return new Promise((resolve, reject) => {

            this.client
                .put(BASE_URL + '/' + id, medium)
                .then(response => {
                    resolve(new ItemResponse(response.status, MediumEntity.fromObject(response.data.data)));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }

    remove(id) {

        return new Promise((resolve, reject) => {

            this.client
                .delete(BASE_URL + '/' + id)
                .then(() => {
                    resolve(true);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }
}