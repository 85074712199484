<template>
    <cmp-header></cmp-header>

    <div class="main-content">
        <div class="wrapper">
            <div class="breadcrumb">
                <router-link v-bind:to="{name: 'game.overview'}">Home</router-link> / Manage platforms
            </div>

            <ul class="form">
                <li class="form-group">
                    <fieldset>
                        <legend>Platforms:</legend>
                        <ul class="form">
                            <li v-for="(item, index) in model.platforms" v-bind:key="item">
                                <vee-form v-bind:ref="el => forms.platforms.value[index] = el" v-slot="{ errors }" v-on:submit="save" v-bind:validation-schema="forms.schemes.platform">
                                    <div class="form-group">
                                        <vee-field v-bind:id="'input-platform-' + index" name="platform" as="select" v-model="model.platforms[index]" class="input-label" required="required" title="" v-bind:class="{error: errors.platform}">
                                            <option v-for="item in platforms" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                                        </vee-field>
                                        <label v-bind:for="'input-platform-' + index">Platform</label>
                                        <vee-error-message name="platform" v-slot="{ message }">
                                            <span class="active error-icon">!</span>
                                            <span class="active error-text">{{ message }}</span>
                                        </vee-error-message>
                                    </div>
                                    <div class="icons">
                                        <span v-if="index === model.platforms.length - 1" v-on:click="platform.add()" class="icon-bg icon-plus"></span>
                                        <span v-on:click="platform.remove(index)" class="icon-bg icon-bin"></span>
                                    </div>
                                </vee-form>
                            </li>
                        </ul>
                    </fieldset>
                </li>
                <li class="align-right">
                    <slot></slot>
                    <input value="Save" v-on:click="save" type="button" class="btn">
                </li>
            </ul>
        </div>
    </div>

    <cmp-footer></cmp-footer>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted, reactive, ref, watch} from 'vue';
import * as yup from 'yup';
import NotificationEntity from '../../entities/notification.entity';
import APIService from '../../services/api/api.service';

export default {

    emits: ['loading'],

    components: {

        'vee-form': require('vee-validate').Form,
        'vee-field': require('vee-validate').Field,
        'vee-error-message': require('vee-validate').ErrorMessage,
        'cmp-header': require('../../components/header-cmp.vue').default,
        'cmp-footer': require('../../components/footer-cmp.vue').default,
    },

    setup(props, { emit }) {

        const store = useStore();
        const api = new APIService();

        const model = reactive({
            platforms: []
        });

        const forms = {

            isValid() {

                const formCollection = forms.platforms.value.filter(form => form);

                return new Promise((resolve, reject) => {

                    Promise.all(formCollection.map(form => form.validate())).then(response => {
                        const valid = response.filter(validation => validation.valid).length === formCollection.length;

                        if(true === valid) {
                            return resolve(true);
                        }

                        reject(false);
                    });
                });
            },

            schemes: {

                platform: yup.object().shape({
                    platform: yup.string().nullable().required('Platform is required'),
                }),
            },

            platforms: ref([])
        }

        const platform = {

            add() {
                model.platforms.push('');
            },

            remove(index) {

                const platforms = model.platforms;

                platforms.splice(index, 1);

                if (0 === platforms.length) {
                    platform.add();
                }
            }
        }

        const platforms = ref([]);

        const save = () => {

            forms.isValid().then(() => {

                let id = 'platform-save';

                emit('loading', id);

                const platforms = model.platforms.map(platform => platform);

                api.platform.update({platforms})
                    .then(response => {

                        store.dispatch('platforms/hydrate', response.items);
                        store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully updated platform list.'));
                    })
                    .catch(error => {

                        if(401 === error.status) {
                            store.dispatch('account/logout');
                            return;
                        }

                        store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error occurred.'));
                    })
                    .finally(() => {
                        emit('loading', id);
                    });

            }).catch(() => {
                store.dispatch('notifications/add', new NotificationEntity('Warning', 'warning', 'There where some validation errors.'));
            });
        }

        const fetch = () => {

            let id = 'platforms-fetch';

            emit('loading', id);

            api.platform.list()
                .then(response => {

                    response.items.sort((a, b) => a.title.localeCompare(b.title));
                    platforms.value = response.items;
                    model.platforms = store.state.platforms.items.map(item => item.id);
                })
                .catch(error => {

                    if(401 === error.status) {
                        store.dispatch('account/logout');
                        return;
                    }

                    store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error occurred.'));
                })
                .finally(() => {
                    emit('loading', id);
                });
        }

        onMounted(fetch);

        return {
            model,
            forms,
            platform,
            platforms,
            save,
        }
    }
};
</script>