export const ORDER_TYPE_DESC = 'desc';
export const ORDER_TYPE_ASC = 'asc';

export const SORT_TYPE_ADDED = 'added';
export const SORT_TYPE_TITLE = 'title';
export const SORT_TYPE_RELEASE = 'release';
export const SORT_TYPE_RELEVANCE = 'relevance';
export const SORT_TYPE_RATING = 'rating';
export const SORT_TYPE_PLATFORM = 'platform';
export const SORT_TYPE_EDITION = 'edition';
export const SORT_TYPE_MEDIUM_TYPE = 'mediumType';
export const SORT_TYPE_PRIORITY = 'priority';

export const DEFAULT_OVERVIEW_SORT = SORT_TYPE_ADDED;
export const DEFAULT_OVERVIEW_ORDER = ORDER_TYPE_DESC;
export const DEFAULT_OVERVIEW_LIMIT = 24;

export const MEDIUM_TYPE_PHYSICAL = 1;
export const MEDIUM_TYPE_DIGITAL = 2;


//Ratings
export const GRADE_AWFUL = 'Awful';
export const GRADE_VERY_BAD = 'Very bad';
export const GRADE_BAD = 'Bad';
export const GRADE_UNIMPRESSIVE = 'Unimpressive';
export const GRADE_AVERAGE = 'Average';
export const GRADE_FAIR = 'Fair';
export const GRADE_ALRIGHT = 'Alright';
export const GRADE_GOOD = 'Good';
export const GRADE_GREAT = 'Great';
export const GRADE_SUPERB = 'Superb';


//Conditions (state)
export const CONDITION_VERY_BAD = 'Very bad';
export const CONDITION_BAD = 'Bad';
export const CONDITION_AVERAGE = 'Average';
export const CONDITION_NEUTRAL = 'Neutral';
export const CONDITION_FAIR = 'Fair';
export const CONDITION_GOOD = 'Good';
export const CONDITION_MINT = 'Mint';

export const CONDITIONS = [
    {condition: '0', title: CONDITION_NEUTRAL},
    {condition: '-3', title: CONDITION_VERY_BAD},
    {condition: '-2', title: CONDITION_BAD},
    {condition: '-1', title: CONDITION_AVERAGE},
    {condition: '1', title: CONDITION_FAIR},
    {condition: '2', title: CONDITION_GOOD},
    {condition: '3', title: CONDITION_MINT},
];


//Owner types
export const OWNER_TYPE_COLLECTION = 'collection';
export const OWNER_TYPE_WISHLIST = 'wishlist';

export const OWNER_TYPES = [
    OWNER_TYPE_COLLECTION,
    OWNER_TYPE_WISHLIST,
]


//Priorities (wishlist)
export const PRIORITY_LOWEST = 'Lowest';
export const PRIORITY_LOW = 'Low';
export const PRIORITY_NEUTRAL = 'Neutral';
export const PRIORITY_HIGH = 'High';
export const PRIORITY_HIGHEST = 'Highest';

export const PRIORITIES = [
    {priority: 1, title: PRIORITY_LOWEST},
    {priority: 2, title: PRIORITY_LOW},
    {priority: 3, title: PRIORITY_NEUTRAL},
    {priority: 4, title: PRIORITY_HIGH},
    {priority: 5, title: PRIORITY_HIGHEST},
];