<template>
    <ul class="data">
        <li>
            <span class="title">Medium type:</span>
            <span class="info">
                <router-link v-bind:to="{name: 'game.overview', query: {'mediumTypes': item.type.id}}">{{ item.type.title }}</router-link>
            </span>
        </li>
        <template v-if="item.type.id === MEDIUM_TYPE_PHYSICAL">
            <li>
                <span class="title">Factory sealed:</span>
                <span class="info">
                    <router-link v-bind:to="{name: 'game.overview', query: {'sealed': item.sealed ? 1 : 0}}">{{ item.sealed ? 'Yes' : 'No' }}</router-link>
                </span>
            </li>
            <li>
                <span class="title">Includes:</span>
                <span class="info">
                    <template v-if="0 === item.properties.length">-</template>
                    <template v-for="property in item.properties" v-bind:key="property.id">
                        <router-link v-bind:to="{name: 'game.overview', query: {'properties': property.id}}">{{ property.title }}</router-link>
                    </template>
                </span>
            </li>
            <li>
                <span class="title">Medium Defects:</span>
                <span class="info">
                    <template v-if="0 === item.defects.length">-</template>
                    <template v-for="defect in item.defects" v-bind:key="defect.id">
                        <router-link v-bind:to="{name: 'game.overview', query: {'defects': defect.id}}">{{ defect.title }}</router-link>
                    </template>
                </span>
            </li>
        </template>
        <li v-if="item.region">
            <span class="title">Region:</span>
            <span class="info">
                <router-link v-bind:to="{name: 'game.overview', query: {'regions': item.region.id}}">{{ item.region.title }}</router-link>
            </span>
        </li>
        <li v-if="item.launcher && item.type.id === MEDIUM_TYPE_DIGITAL">
            <span class="title">Launcher:</span>
            <span class="info">
                <router-link v-bind:to="{name: 'game.overview', query: {'launchers': item.launcher.id}}">{{ item.launcher.title }}</router-link>
            </span>
        </li>
        <li>
            <span class="title">Languages:</span>
            <span class="info">
                <template v-for="language in item.languages" v-bind:key="language.id">
                    <router-link v-bind:to="{name: 'game.overview', query: {'languages': language.id}}">{{ language.title }}</router-link>
                </template>
            </span>
        </li>
        <li v-if="item.edition">
            <span class="title">Edition:</span>
            <span class="info">
                <router-link v-bind:to="{name: 'game.overview', query: {'editions': item.edition.id}}">{{ item.edition.title }}</router-link>
            </span>
        </li>
        <li v-if="item.type.id === MEDIUM_TYPE_PHYSICAL">
            <span class="title">Condition:</span>
            <span class="info">
                <router-link v-bind:to="{name: 'game.overview', query: {'states': item.condition.state}}">{{ item.condition.title }}</router-link>
            </span>
        </li>
        <li v-if="item.notes">
            <span class="title">User notes:</span>
            <span class="info">{{ item.notes }}</span>
        </li>
    </ul>
</template>

<script>
import MediumEntity from '../../../entities/game/medium.entity';
const { MEDIUM_TYPE_PHYSICAL, MEDIUM_TYPE_DIGITAL } = require('../../../constants');

export default {

    props: {
        item: {
            type: MediumEntity
        }
    },

    setup() {
        return {
            MEDIUM_TYPE_PHYSICAL,
            MEDIUM_TYPE_DIGITAL,
        }
    }
}
</script>