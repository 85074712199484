<template>
    <header>
        <div class="wrapper">
            <div class="head">
                <div class="logo-container">
                    <router-link v-bind:to="{name: 'game.overview'}">
                        <cmp-logo v-bind:hover="true"></cmp-logo>
                    </router-link>
                </div>
                <span class="bars" v-on:click="menu.toggle('nav')">
                    <i class="icon-bars"></i>
                </span>
                <ul class="nav" v-bind:class="{active: menu.nav.value}">
                    <li v-bind:class="{active: search.enabled.value}" class="search">
                        <input type="text" v-on:keyup.enter="search.submit" v-on:keyup="search.suggest" v-model="search.model.value" v-on:focus="search.open">
                        <button><i class="icon-search" v-on:click="search.submit"></i></button>
                        <ul class="suggestions">
                            <li class="platform" v-for="platform in search.suggestions.value" v-bind:key="platform.id">
                                <span class="title">{{ platform.title }}</span>
                                <ul>
                                    <template v-for="game in platform.games" v-bind:key="game.id">
                                        <li v-for="medium in game.mediums" v-bind:key="medium.id">
                                            <router-link v-bind:to="{ name: 'game.detail', params: { id: game.id, slug: game.slug }, hash: '#' + medium.id}" class="game">{{ game.title }}</router-link>
                                        </li>
                                    </template>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li v-bind:class="{active: menu.games.value}" v-on:click="menu.toggle('games')" class="menu">
                        <span class="title"><i class="icon-gamepad"></i>Games<i class="icon-caret-down"></i></span>
                        <ul class="sub-menu" v-bind:class="{active: menu.games.value}">
                            <li>
                                <router-link v-bind:to="{name: 'game.overview'}">
                                    <i class="icon-database"></i>
                                    <span>Collection</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name: 'wishlist.overview'}">
                                    <i class="icon-heart"></i><span>Wishlist</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name: 'game.add'}">
                                    <i class="icon-plus-circle"></i>
                                    <span>Add Game</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li v-bind:class="{active: menu.account.value}" v-on:click="menu.toggle('account')" class="menu">
                        <span class="title"><i class="icon-user"></i>{{ account.user().username }}<i class="icon-caret-down"></i></span>
                        <ul class="sub-menu" v-bind:class="{active: menu.account.value}">
                            <li>
                                <router-link v-bind:to="{name: 'account.platforms'}">
                                    <i class="icon-tv"></i>
                                    <span>Platforms</span>
                                </router-link>
                            </li>
                            <li v-on:click="account.logoff">
                                <a>
                                    <i class="icon-logoff"></i>
                                    <span>Logoff</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
import {SORT_TYPE_RATING, ORDER_TYPE_DESC, SORT_TYPE_RELEVANCE} from '@/constants';
import {useRouter, useRoute, onBeforeRouteUpdate} from 'vue-router';
import {ref, inject, onMounted, onUnmounted, watch} from 'vue';
import APIService from '../services/api/api.service';

export default {

    components: {
        'cmp-logo': require('./logo-cmp.vue').default,
    },

    setup() {

        const router = useRouter();
        const route = useRoute();
        const account = inject('account');
        const api = new APIService();

        const menu = {
            games: ref(false),
            account: ref(false),
            nav: ref(false),

            toggle(type) {

                ['games', 'account', 'nav'].forEach(item => {

                    if(item === type) {
                        menu[type].value = !menu[type].value;
                    }
                    else {
                        menu[item].value = false;
                    }
                });
            },

            close(e) {

                if(undefined !== e && (null !== e.target.closest('.menu') || null !== e.target.closest('.bars') || null !== e.target.closest('.nav'))) {
                    return;
                }

                menu.games.value = false;
                menu.account.value = false;
                menu.nav.value = false;
            }
        }

        const search = {

            model: ref(route.query.title),

            debounce: null,

            suggestions: ref([]),

            enabled: ref(false),

            close(e) {

                if(undefined !== e && (null !== e.target.closest('.search'))) {
                    return;
                }

                search.enabled.value = false;
            },

            open() {

                if(search.model.value && 0 === search.model.value.length) {
                    return;
                }

                search.enabled.value = true;
            },

            navigation(e) {

                //Down
                e.keyCode === 40

                //Up
                e.keyCode === 38

                
            },

            submit() {

                const query = {...route.query};
                delete query.title;
                delete query.page;

                if(search.model.value.length > 0) {
                    query.title = search.model.value;
                    query.sort = SORT_TYPE_RELEVANCE;
                }

                menu.close();
                router.push({name: 'game.overview', query, params: {unique: new Date().getTime()}});
            },

            suggest() {

                clearInterval(search.debounce);

                if(undefined === search.model.value || 0 === search.model.value.length) {
                    search.enabled.value = false;
                    return;
                }

                search.debounce = setTimeout(async function() {
                    search.suggestions.value = await api.game.suggest(search.model.value);

                    if(search.suggestions.value.length > 0) {
                        search.enabled.value = true;
                    }
                }, 300);
            }
        }

        onMounted(() => {
            document.body.addEventListener('click', menu.close);
            document.body.addEventListener('click', search.close);
            document.body.addEventListener('keydown', search.navigation);
        });

        onUnmounted(() => {
            document.body.removeEventListener('click', menu.close);
            document.body.removeEventListener('click', search.close);
            document.body.removeEventListener('keydown', search.navigation);
        });

        onBeforeRouteUpdate(to => {

            search.enabled.value = false;
            search.model.value = to.query.title ? to.query.title : null;
        });

        return {
            search,
            account,
            menu,
            SORT_TYPE_RATING,
            ORDER_TYPE_DESC,
        }
    }
}
</script>

<style scoped>
/* Navigation */
.icon-bars {
    font-size: 25px;
    color: rgb(193, 193, 193);
    font-weight: 300;
    cursor: pointer;
}
.head input[type="radio"], .head input[type="checkbox"] {
    display: none;
}
.bars {
    display: none;
}
.nav {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
}

/* Search input */
.nav .search {
    display: flex;
    flex-basis: 300px;
}
.nav .search button {
    height: 34px;
    border-radius: 0 4px 4px 0;
    width: 60px;
    border-left: 1px solid #dde3e8;
    cursor: pointer;
    position:relative;
    z-index: 2
}
.nav .search button:hover {
    border-left: 1px solid #adadad;
}
.nav .search button:hover [class*='icon-'] {
    color: var(--theme-hover-color);
}
.nav .search input {
    border-color: #ececec;
    border-right: none;
    background: #ececec;
    border-left: none;
    outline: none;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.43;
    color: #555555;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    border-radius: 4px 0 0 4px;
    width: 100%;
    position:relative;
    z-index: 2;
}
.nav .search input:focus {
    border-color: #ececec;
}
.nav .search [class*='icon-'] {
    color: var(--theme-color);
}

/* Search suggestions */
.nav .search .suggestions {
    display: none;
    position: absolute;
    top: 32px;
    border-radius: 0 0 4px 4px;
    background: #ffffff;
    left: 0;
    right: 0;
    z-index: 5;
    color: #fff;
    border-top: 1px solid #dde3e8;
    box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.7), 10px 0 10px -10px rgba(0, 0, 0, 0.7), 0 10px 10px -10px rgba(0, 0, 0, 0.7);
    max-height: 75vh;
    overflow: scroll;
}
.nav .search.active .suggestions {
    display: block;
}
.nav .search .platform {
    margin-top: 10px
}
.nav .search .suggestions .title {
    font-size: 1.2rem;
    color: var(--theme-color);
    padding: 10px 10px 0 10px;
    display:block;
}
.nav .search .platform a.game {
    color: #34373b;
    padding: 10px
}
.nav .search .platform a.game:hover {
    background: #e9e9ed;
}

/* Sub menu */
.nav .sub-menu {
    user-select: none;
    color: #fff;
    position: relative;
    height: inherit;
    line-height: 60px;
    gap: 10px;
    cursor: pointer;
    align-items: baseline;
    white-space: nowrap;
}
.nav li [class*='icon-'] {
    color: #fff;
    font-size: 15px;
}

@media (min-width: 750px) {
    .nav .menu .title {
        display: flex;
        color: #fff;
        gap: 15px;
        cursor: pointer;
        height: 60px;
        align-items: center;
        padding: 0 10px;
    }
    .nav .menu.active {
        background: var(--theme-color);
    }
    .nav .sub-menu {
        position: absolute;
        color: #fff;
        background: var(--theme-color);
        top: 60px;
        right: 0;
        z-index: 50;
        min-width: 100%;
        display: none;
        line-height: initial;
        text-transform: initial;
    }
    .nav .sub-menu.active {
        display: block;
        box-shadow: 0 4px 3px 0 rgba(0, 0, 0, .1);
    }
    .nav li {
        position: relative;
    }
    .nav li a {
        padding: 15px;
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .nav li a span {
        color: #fff;
    }
    .nav li a:hover {
        background: var(--theme-hover-color);
        text-decoration: none;
    }
    .nav .search {
        width: 290px;
    }
}

@media (max-width: 749px) {

    .bars {
        display: block;
    }
    .nav {
        display: none;
        position: absolute;
        z-index: 6;
        top: 60px;
        left: 0;
        right: 0;
        background: var(--theme-color);
        box-shadow: 0 20px 83px 0 rgba(0, 0, 0, 0.4);
        padding-bottom: 10px;
    }
    .nav.active {
        display: block;
    }
    .nav li a {
        padding: 0 3%;
        display: flex;
        width: 100%;
        align-items: center;
        gap: 8px;
    }
    .nav li a span {
        color: #fff;
    }
    .nav li a:hover {
        background: var(--theme-hover-color);
        text-decoration: none;
    }
    .nav .search {
        padding: 20px 20px 10px 20px;
    }
    .nav .search button, .nav .search input {
        height: 38px;
    }
    .nav .menu .title {
        display: none;
    }
    .nav .search.active .suggestions {
        margin: 24px 20px 0 20px;
    }
}
</style>