'use strict';

import { CONDITIONS } from '@/constants';

export default class ConditionEntity
{
    constructor(state)
    {
        this.state = parseInt(state);
    }

    get title() {

        const condition = CONDITIONS.find(condition => parseInt(condition.condition) === this.state);

        if(undefined !== condition) {
            return condition.title;
        }

        return null;
    }
}