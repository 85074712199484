'use strict';

import AbstractEndpoint from './abstract.endpoint';
import ErrorResponse from '../../../entities/response/error.response';
import CollectionResponse from '../../../entities/response/collection.response';
import PlatformEntity from '../../../entities/game/platform.entity';

const BASE_URL = 'platform';

export default class PlatformEndpoint extends AbstractEndpoint
{
    update(platforms) {

        return new Promise((resolve, reject) => {

            this.client
                .put(BASE_URL, platforms)
                .then(response => {

                    const collection = new CollectionResponse(response.status);

                    response.data.data.map(item => {
                        collection.add(PlatformEntity.fromObject(item));
                    });

                    resolve(collection);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }

    list() {

        return new Promise((resolve, reject) => {

            this.client
                .get(BASE_URL)
                .then(response => {

                    const collection = new CollectionResponse(response.status, response.data.pagination);

                    response.data.data.map(item => {
                        collection.add(PlatformEntity.fromObject(item));
                    });

                    resolve(collection);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }
}