<template>
    <div class="panel-container panel-left filter-container" v-bind:class="{'active': enabled}">
        <div class="filters content">
            <section class="panel-title">
                <h3>Medium filters<template v-if="active.medium.value > 0">&nbsp;<a v-on:click="filter.deselect('medium')">({{ active.medium.value }})</a></template>:</h3>
                <i class="close icon-close" v-on:click="filter.close"></i>
            </section>
            <template v-for="(item, type) in filters" v-bind:key="item.id">

                <section v-if="'medium' === item.group">
                    <input type="checkbox" class="toggle" v-model="filters[type].open" v-bind:id="'filter-' + type">
                    <label v-bind:for="'filter-' + type">
                        <span class="filter-type">
                            {{ item.title }}:
                            <i class="down icon-arrow-down"></i>
                            <i class="up icon-arrow-up"></i>
                        </span>
                    </label>
                    <ul>
                        <li v-if="true === filters[type].multiple">
                            <label class="switch">
                                <input type="checkbox" v-model="filters[type].include" v-on:change="filter.exclude(type)">
                                <span class="slider round"></span>
                            </label>
                        </li>
                        <li v-for="item in filters[type].items" v-bind:key="item.id">
                            <label class="checkbox-container">
                                <template v-if="true === filters[type].multiple">
                                    <input type="checkbox" v-bind:value="item.id" v-model="filters[type].selected" v-on:change="filter.update">
                                    <span class="checkmark"></span>
                                </template>
                                <template v-else>
                                    <input type="radio" v-bind:name="type" v-bind:value="item.id" v-model="filters[type].selected" v-on:change="filter.update">
                                    <span class="radiomark"></span>
                                </template>
                                <span class="title">{{ item.title }}</span>
                                <template v-if="aggregations[type]">
                                    <span class="aggregation" v-if="aggregations[type][item.id] > 0">{{ aggregations[type][item.id] }}</span>
                                    <span class="aggregation" v-else>0</span>
                                </template>
                            </label>
                        </li>
                    </ul>
                </section>
            </template>
            <section class="panel-title">
                <h3>Game filters<template v-if="active.game.value > 0">&nbsp;<a v-on:click="filter.deselect('game')">({{ active.game.value }})</a></template>:</h3>
            </section>
            <template v-for="(item, type) in filters" v-bind:key="item.id">
                <section v-if="'game' === item.group">
                    <input type="checkbox" class="toggle" v-model="filters[type].open" v-bind:id="'filter-' + type">
                    <label v-bind:for="'filter-' + type">
                        <span class="filter-type">
                            {{ item.title }}:
                            <i class="down icon-arrow-down"></i>
                            <i class="up icon-arrow-up"></i>
                        </span>
                    </label>
                    <ul>
                        <li>
                            <label class="switch">
                                <input type="checkbox" v-model="filters[type].include" v-on:change="filter.exclude(type)">
                                <span class="slider round"></span>
                            </label>
                        </li>
                        <li v-for="item in filters[type].items" v-bind:key="item.id">
                            <label class="checkbox-container">
                                <input type="checkbox" v-bind:value="item.id" v-model="filters[type].selected" v-on:change="filter.update">
                                <span class="checkmark"></span>
                                <span class="title">{{ item.title }}</span>
                                <template v-if="aggregations[type]">
                                    <span class="aggregation" v-if="aggregations[type][item.id] > 0">{{ aggregations[type][item.id] }}</span>
                                    <span class="aggregation" v-else>0</span>
                                </template>
                            </label>
                        </li>
                    </ul>
                </section>
            </template>
        </div>
        <div class="background" v-on:click="filter.close"></div>
    </div>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import { useStore } from 'vuex';
import { CONDITIONS } from '@/constants';

export default {

    props: {
        enabled: {
            type: Boolean,
            default: false,
        },
        selected : {
            type: Object,
            default: () => {},
        },
        aggregations : {
            type: Object,
            default: () => {},
        }
    },

    setup(props, {emit}) {

        const store = useStore();

        const status = {
            loaded: ref(false)
        }

        const active = {

            counter(group) {

                let amount = 0;

                for(const type in filters) {

                    if(filters[type].group === group) {

                        if(true === filters[type].multiple) {
                            amount += filters[type].selected.length;
                        }

                        else if(undefined !== filters[type].selected) {
                            amount += 1;
                        }
                    }
                }

                return amount;
            },

            game: computed(() => {
                return active.counter('game');
            }),

            medium: computed(() => {
                return active.counter('medium');
            }),
        }

        const filters = reactive({

            genres: {
                title: 'Genres',
                items: computed(() => store.state.genres.items),
                selected: ref([]),
                open: ref(false),
                group: 'game',
                include: ref(true),
                multiple: true,
            },
            playerPerspectives: {
                title: 'Player perspectives',
                items: computed(() => store.state['player-perspectives'].items),
                selected: ref([]),
                open: ref(false),
                group: 'game',
                include: ref(true),
                multiple: true,
            },
            gameModes: {
                title: 'Game modes',
                items: computed(() => store.state['game-modes'].items),
                selected: ref([]),
                open: ref(false),
                group: 'game',
                include: ref(true),
                multiple: true,
            },
            themes: {
                title: 'Themes',
                items: computed(() => store.state.themes.items),
                selected: ref([]),
                open: ref(false),
                group: 'game',
                include: ref(true),
                multiple: true,
            },
            platforms: {
                title: 'Platforms',
                items: computed(() => store.state.platforms.items),
                selected: [],
                open: false,
                group: 'medium',
                include: false,
                multiple: true,
            },
            mediumTypes: {
                title: 'Types',
                items: computed(() => store.state['medium-types'].items),
                selected: ref([]),
                open: ref(false),
                group: 'medium',
                include: ref(true),
                multiple: true,
            },
            regions: {
                title: 'Regions',
                items: computed(() => store.state.regions.items),
                selected: ref([]),
                open: ref(false),
                group: 'medium',
                include: ref(true),
                multiple: true,
            },
            languages: {
                title: 'Languages',
                items: computed(() => store.state.languages.items),
                selected: ref([]),
                open: ref(false),
                group: 'medium',
                include: ref(true),
                multiple: true,
            },
            properties: {
                title: 'Includes',
                items: computed(() => store.state.properties.items),
                selected: ref([]),
                open: ref(false),
                group: 'medium',
                include: ref(true),
                multiple: true,
            },
            editions: {
                title: 'Editions',
                items: computed(() => store.state.editions.items),
                selected: ref([]),
                open: ref(false),
                group: 'medium',
                include: ref(true),
                multiple: true,
            },
            launchers: {
                title: 'Launchers',
                items: computed(() => store.state.launchers.items),
                selected: ref([]),
                open: ref(false),
                group: 'medium',
                include: ref(true),
                multiple: true,
            },
            seals: {
                title: 'Factory sealed',
                items: ref([{id: '1', title: 'Yes'}, {id: '0', title: 'No'}]),
                selected: ref('0'),
                open: ref(false),
                group: 'medium',
                include: ref(true),
                multiple: false,
            },
            defects: {
                title: 'Defects',
                items: computed(() => store.state.defects.items),
                selected: ref([]),
                open: ref(false),
                group: 'medium',
                include: ref(true),
                multiple: true,
            },
            states: {
                title: 'Condition',
                items: computed(() => {

                    return CONDITIONS.map(item => {
                        return {
                            id: item.condition,
                            title: item.title
                        }
                    });
                }),
                selected: ref([]),
                open: ref(false),
                group: 'medium',
                include: ref(true),
                multiple: true,
            },
        });

        const filter = {

            close() {
                emit('close');
            },

            exclude(type) {

                if(filters[type].selected.length > 0) {
                    filter.update();
                }
            },

            update() {

                const query = {};

                for(const type in filters) {

                    if(false === filters[type].multiple) {

                        const value = filters[type].selected;

                        if(undefined === value) {
                            continue;
                        }

                        query[type] = {
                            //filters: Array.isArray(value) ? value : [value],
                            filters: value,
                            included: filters[type].include,
                        };

                        continue;
                    }

                    query[type] = {

                        filters: filters[type].selected.map(id => parseInt(id)),
                        included: filters[type].include,
                    };
                }

                emit('update', query);
            },

            deselect(group) {

                for(const type in filters) {

                    if(filters[type].group === group) {

                        filters[type].selected = [];
                        filters[type].include = true;
                    }
                }

                filter.update();
            },

            fill() {

                for(const type in props.selected) {

                    if(undefined === filters[type]) {
                        continue;
                    }

                    if(false === filters[type].multiple) {

                        filters[type].selected = props.selected[type].filters[0];
                        continue;
                    }

                    filters[type].selected = props.selected[type].filters;
                    filters[type].include = props.selected[type].included;
                }

                status.loaded.value = true;
            }
        };

        watch(() => props.selected, () => {
            filter.fill();
        }, {deep: true});

        watch(status.loaded, () => {

            for(const type in filters) {

                if(!filters[type].selected) {
                    continue;
                }

                filters[type].open = filters[type].selected.length > 0;

            }
        });

        return {
            filters,
            filter,
            active,
            status,
            CONDITIONS
        }
    }
}
</script>

<style>
.filter-container {
    width: 30%;
    position: relative;
    margin-top: -80px;
    padding-right: 10px;
}
.filter-container .filters {
    padding-right: 10px;
}
.filter-container .filters .filter-type {
    color: #333;
    font-weight: 300;
    font-size: 13px;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    gap: 5px;
    font-family: "poppins-semi-bold", serif;
}
.filter-container .filters section {
    border-bottom: 1px solid var(--theme-color);
    padding: 10px;
}
.filter-container .filters section .toggle {
    display: none;
}
.filter-container .filters section ul {
    display: none;
}
.filter-container .filters section .toggle:checked ~ ul {
    display: block;
    margin-top: .5rem;
}
.filter-container .filters section .toggle:checked ~ label .up {
    display: block;
}
.filter-container .filters section .toggle:checked ~ label .down {
    display: none;
}
.filter-container .filters section .down {
    font-size: 16px;
}
.filter-container .filters section .up {
    display: none;
    font-size: 16px;
}
.filter-container .panel-title {
    padding: 10px;
}
@media screen and (max-width: 749px) {
    .filter-container .filters {
        left: 0;
        padding-left: 10px;
    }
}


/* Checkbox */
.checkbox-container {
    display: flex;
    gap: 10px;
    position: relative;
    cursor: pointer;
    user-select: none;
    height: 20px;
    margin-bottom: .625rem;
}
.checkbox-container .title {
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
.checkbox-container .aggregation {
    margin-left: auto;
    color: #a6a6a6;
}
.checkbox-container input {
    display: none;
}
.checkbox-container input:checked ~ .checkmark {
    border: none;
}
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
.checkbox-container .checkmark {
    display: inline-block;
    height: 20px;
    min-width: 20px;
    background: #ddd;
    border: 1px solid #eee;
}
.checkbox-container .checkmark:after {
    content: '';
    display: none;
    position: absolute;
    width: 4px;
    height: 11px;
    margin: 2px 0 0 8px;
    border: solid #555;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.checkbox-container:hover .checkmark {
    border: none;
}


/* Radio box */
.checkbox-container input:checked ~ .radiomark {
    border: none;
}
.checkbox-container input:checked ~ .radiomark:after {
    display: block;
}
.checkbox-container .radiomark {
    display: inline-block;
    height: 20px;
    min-width: 20px;
    background: #ddd;
    border: 1px solid #eee;
    border-radius: 50%;
    position: relative;
}
.checkbox-container .radiomark:after {
    content: '';
    display: none;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #555;
    border-radius: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}
.checkbox-container:hover .radiomark {
    border: none;
}


/* Toggle / Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 25px;
}
.switch input {
    display:none;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 5px;
    background-color: #ddd;
    transition: .3s;
    transform: translateX(85px);
}
input:checked + .slider {
    background-color: var(--theme-color);
}
input:checked + .slider:before {
    transform: translateX(0);
}
.slider:after {
    content: 'Exclude';
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
}
input:checked + .slider:after {
    content: 'Include';
}
</style>